<template>
    <div>
        <div class="addProjects-content">
        <div class="addProjects-input">
          <span>日用水</span>
          <div>
            <el-input v-model="inputs1"
            oninput="value=value.replace(/[^0-9.]/g,'')"
                size="mini"
                placeholder="请输入日用水"></el-input>
          </div>
          <span>月用水</span>
          <div>
            <el-input v-model="inputs2"
            oninput="value=value.replace(/[^0-9.]/g,'')"
                size="mini"
                placeholder="请输入月用水"></el-input>
          </div>
            </div>
            </div>
        <div class="addProjects-content">
            <div class="addProjects-input">
          <span>日用电</span>
          <div>
            <el-input v-model="inputs3"
            oninput="value=value.replace(/[^0-9.]/g,'')"
                size="mini"
                placeholder="请输入日用电"></el-input>
          </div>
          <span>月用电</span>
          <div>
            <el-input v-model="inputs4"
            oninput="value=value.replace(/[^0-9.]/g,'')"
                size="mini"
                placeholder="请输入月用电"></el-input>
          </div>
            </div>
            </div>

            <el-button @click="AddExtractApplyfn"
                class="btns"
                type="primary"
                size="small"
                style="width:200px;margin-left:5%;margin-top:10%"
                :disabled="!inputs1 || !inputs2|| !inputs3|| !inputs4">保存</el-button>
    </div>
</template>


<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage,ElMessageBox } from "element-plus";
import addyard from '@/components/addyard.vue'
import previewImg from '@/components/previewimg.vue'
import {Get_wae_ThresholdValueData,InsertOrUpdate_wae_ThresholdValue} from '@/js/safeDisclose'
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import {DelPictureList} from "@/js/indexApi.js";
export default{
    setup() {
         const state = reactive({
            inputs1:'',
            inputs2:'',
            inputs3:'',
            inputs4:'',
            id:'',
         })
          const cdata = getStorage("bscdata");
          const methods = {
            Get_wae_ThresholdValueData(){
                let params = {
                    projectId:cdata.pid,
                }
                Get_wae_ThresholdValueData(params).then(res=>{
                     if (res.data.Code == 1) {
                       if (res.data.Data) {
                        state.inputs1 = res.data.Data.waterDay;
                        state.inputs2 = res.data.Data.waterMonth;
                        state.inputs3 = res.data.Data.electricityDay;
                        state.inputs4 = res.data.Data.electricityMonth;
                        state.id = res.data.Data.ID;
                       }
                        
                    } else {
                        ElMessage({
                        showClose: true,
                        message: res.data.message,
                        type: "error",
                        });
                    }
                })
            },

            AddExtractApplyfn(){
                let params = {}
                if (state.id) {
                      params = {
                    electricityDay:state.inputs3,
                    electricityMonth:state.inputs4,
                    waterDay:state.inputs1,
                    waterMonth:state.inputs2,
                    insertUser: cdata.uid,
                    projectID: cdata.pid,
                    id:state.id
                }
                }else{
                     params = {
                    electricityDay:state.inputs3,
                    electricityMonth:state.inputs4,
                    waterDay:state.inputs1,
                    waterMonth:state.inputs2,
                    insertUser: cdata.uid,
                    projectID: cdata.pid,
                }
                }
                
                InsertOrUpdate_wae_ThresholdValue(params).then(res=>{
                    if (res.data.Code == 1) {
                    ElMessage({
                    showClose: true,
                    message: res.data.Message,
                    type: "success",
                    });
                    methods.Get_wae_ThresholdValueData();
                } else {
                    ElMessage({
                    showClose: true,
                    message: res.data.Message,
                    type: "error",
                    });
                }
                })
            },
          }
           onMounted(() => {
            methods.Get_wae_ThresholdValueData();

        });

         return {
      ...methods,
      ...toRefs(state)
    };
    },
}
</script>

<style lang="scss" scoped>
  .addProjects-content {
    width: 100%;
    padding: 0 20px 60px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:deep(.img-list) {
        width: 81px;
        height: 63px;
        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 81px;
            height: 63px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 81px;
          height: 63px;
          position: relative;
          background: #f3f3f3;
          border: 1px dashed #999999;
          border-radius: 0;
          .up-icon {
            width: 34px;
            height: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          i {
            position: absolute;
            right: 12px;
            bottom: 8px;
            font-size: 13px;
            color: #999999;
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      .list-title {
        position: absolute;
        left: 186px;
        bottom: 2px;
      }

      span {
        display: inline-block;
        width: 110px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    &:deep(#div1) {
        .w-e-text{
           p{
          b{
            font-weight: bold !important;
            }
          i{
            font-style:italic !important;
            }
      }
        }
       
    }



    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }
   .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
</style>